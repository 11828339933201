import { FreePlanBlockProps } from './FreePlanBlock.types';
import { Container } from './FreePlanBlock.styles';
import { useUserContext } from '../../../contexts/UserContext';

export const FreePlanBlock = ({ children, ...otherProps }: React.PropsWithChildren<FreePlanBlockProps>) => {
  const user = useUserContext();

  if (!user) return null;

  const { isMember } = user;

  if (isMember) return null;

  return (
    <>
      {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
      {children}
    </>
  );
};
